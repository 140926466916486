import React from 'react';
import Layout from '../components/Layout';

export default () => (
    <Layout>
        <div className="columns">
            <div className="column is-10 is-offset-3">
                <h1>🤷‍♀️ 🤦‍♂️👎 ...not found</h1>
            </div>
        </div>
    </Layout>
);
